<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-card elevation="4">
      <v-toolbar flat>
        <v-toolbar-title>{{ $t("categories") }}</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-row justify="end">
          <add-products-dialog v-if="$admin.can('category-update')">
          </add-products-dialog>
          <edit-dialog v-if="$admin.can('category-update')"> </edit-dialog>
          <show-dialog v-if="$admin.can('category-view')"> </show-dialog>

          <v-dialog
            v-if="$admin.can('category-delete')"
            v-model="isVisibleDeleteDialog"
            max-width="500px"
          >
            <v-card>
              <v-card-title class="text-h5">{{
                $t("delete_confirmation_message")
              }}</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red darken-1"
                  text
                  @click="closeForm"
                  >{{ $t("cancel") }}</v-btn
                >
                <v-btn
                  color="primary"
                  text
                  @click="destroyConfirm(editedCategory)"
                  >{{ $t("ok") }}</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn
            color="primary"
            @click="getCSV(options)"
            :loading="is_loading_csv"
            :disabled="is_loading_csv"
          >
            <v-icon
              color="white"
              class="mr-2"
            >
              mdi-arrow-down-bold-circle-outline
            </v-icon>
            {{ $t("export_csv") }}
          </v-btn>
          <!-- <v-btn v-if="$admin.can('category-delete-all-unused')" color="red" @click="destroyUnused">
                    {{ $t("delete_unused")}}
                </v-btn> -->
        </v-row>
      </v-toolbar>
      <v-treeview
        open-all
        dense
        hoverable
        :items="rootCategories"
      >
        <template v-slot:append="{ item }">
          <v-chip v-if="item.code">
            {{ item.code }}
          </v-chip>
          <v-chip
            :color="getColor(item.status)"
            dark
            class="mx-15 my-2"
          >
            {{ item.status }}
          </v-chip>

          <v-icon
            small
            class="mx-1"
            @click="show(item)"
          >
            mdi-eye
          </v-icon>
          <v-icon
            small
            class="mx-1"
            @click="edit(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            v-if="item?.level > 2"
            small
            class="mx-1"
            @click="addProducts(item)"
          >
            mdi-database-edit
          </v-icon>
          <span
            class="mx-1"
            v-else
          >
            &nbsp;
          </span>
          <v-icon
            small
            class="mx-1"
            @click="destroy(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-treeview>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { validationRules } from "@/mixins/validationRules";
import { helpers } from "@/mixins/helpers";

export default {
  mixins: [validationRules, helpers],

  async mounted() {
    await this.$store.dispatch("specialCategories/listRoots");
    await this.$store.dispatch("specialCategories/list");
  },

  components: {
    AddProductsDialog: () =>
      import("./special-categories/dialogs/AddProducts.vue"),
    EditDialog: () => import("./special-categories/dialogs/Edit.vue"),
    ShowDialog: () => import("./special-categories/dialogs/Show.vue"),
  },
  computed: {
    ...mapGetters({
      isLoadingCategories: "specialCategories/isLoadingCategories",
      rootCategories: "specialCategories/listRoots",
      listCategories: "specialCategories/listCategories",

      listStatuses: "statuses/listStatuses",
    }),
  },
  data() {
    return {
      is_valid_form: true,
      is_valid_csv: true,
      is_uploading: false,
      is_loading_csv: false,
      is_deleting_unused: false,
      isVisibleDeleteDialog: false,
      isVisibleCSVDialog: false,
      editedCategory: {},
      categories: {},
      headers: [
        {
          text: this.$t("name"),
          align: "start",
          value: "name",
        },
        {
          text: this.$t("parent"),
          align: "start",
          value: "parent.name",
          width: "15%",
        },
        {
          text: this.$t("status"),
          align: "center",
          value: "status",
          width: "15%",
        },
        {
          text: this.$t("actions"),
          align: "end",
          sortable: false,
          value: "actions",
          width: "15%",
        },
      ],
    };
  },
  methods: {
    edit(item) {
      this.$store.dispatch("specialCategories/openEditForm", item);
    },
    addProducts(item) {
      this.$store.dispatch("specialCategories/openAddProductsForm", item);
    },
    show(item) {
      this.$store.dispatch("specialCategories/openShowForm", item);
    },

    destroy(item) {
      this.isVisibleDeleteDialog = true;
      this.editedCategory = Object.assign({}, item);
    },

    async destroyConfirm(item) {
      try {
        await this.$store.dispatch("specialCategories/destroy", item);
        await this.$store.dispatch("specialCategories/listRoots");

        this.$store.dispatch("alerts/success", this.$t("deleted_successfully"));
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
      this.closeForm();
    },

    async destroyUnused() {
      this.is_deleting_unused = true;
      try {
        await this.$store.dispatch("specialCategories/destroyUnused");
        await this.$store.dispatch("specialCategories/list");

        this.$store.dispatch("alerts/success", this.$t("deleted_successfully"));
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
      this.is_deleting_unused = false;
    },

    closeForm() {
      this.isVisibleCSVDialog = false;
      this.isVisibleDeleteDialog = false;
      this.editedCategory = {};
    },

    async getCSV(option) {
      this.is_loading_csv = true;
      await this.$store
        .dispatch("specialCategories/exportCSV", option)
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "specialCategories.csv");
          document.body.appendChild(link);
          // window.open(link);
          link.click();
          this.is_loading_csv = false;
        })
        .catch((error) => {
          this.$store.dispatch("alerts/error", error.response?.statusText);
          this.is_loading_csv = false;
        });
    },

    onFileChanged(e) {
      this.categories.csv = e;
    },

    getColor(status) {
      if (status === "active") return "green";
      else return "red";
    },
  },
};
</script>

<style lang="sass">
.v-treeview-node__root
  padding: 0 15px
  border-top: solid 1px #e1e1e1
</style>
